<template>
  <v-container fluid>
    <!-- Componente para manejar productos -->
    <dialprod
      ref="dialprod"
      :orderName="order.nameOrder"
      @prod="updateValue"
    ></dialprod>

    <!-- Tooltip para el botón de finalizar pedido -->
    <v-tooltip bottom v-if="!view && completedDelivery">
      <template v-slot:activator="{ on }">
        <i-btn
          v-on="on"
          :title="$t('finished')"
          outlined
          classes="mx-1"
          icon="fa-check-double"
          color="success"
          @click="update('finished')"
        />
      </template>
    </v-tooltip>

    <!-- Tarjeta que muestra la información del pedido -->
    <v-card class="infocard" v-if="view">
      <v-card-title :class="cardTitleClasses">
        <!-- Componente para el cuerpo del email -->
        <getBodyEmail
          v-if="quote.state === 'sended_quote'"
          app_label="sales"
          model="quotation"
          :condition="quote.state === 'sended_quote'"
          :custumerNotification="customer.notification"
          stateMain="sended_quote"
          :subject="$t('quote')"
          :recipient_list="customer.email"
          :email_notification="true"
          :orderId="quote.order"
          ref="getbodyemail"
        />
        <!-- Botón para enviar cotización -->
        <v-tooltip bottom v-if="quote.state === 'quote'">
          <template v-slot:activator="{ on }">
            <i-btn
              v-if="changeStatus.edit"
              v-on="on"
              :title="$t('send_quote')"
              outlined
              classes="mx-1"
              icon="fa-paper-plane"
              color="success"
              @click="update('send')"
            />
          </template>
        </v-tooltip>

        <!-- Botón para aprobar cotización -->
        <v-tooltip
          bottom
          v-if="quote.state === 'quote' || quote.state === 'sended_quote'"
        >
          <template v-slot:activator="{ on }">
            <i-btn
              v-if="changeStatus.edit"
              v-on="on"
              :title="$t('aproved_quote')"
              outlined
              classes="mx-1"
              icon="fa-check"
              color="success"
              @click="update('approved')"
            />
          </template>
        </v-tooltip>

        <!-- Botón para marcar como producción lista -->
        <v-tooltip bottom v-if="quote.state === 'in_process'">
          <template v-slot:activator="{ on }">
            <i-btn
              v-if="changeStatus.edit"
              v-on="on"
              :title="$t('production_ready')"
              outlined
              classes="mx-1"
              icon="fa-check"
              color="warning"
              @click="update('production_ready')"
            />
          </template>
        </v-tooltip>

        <!-- Botón para cancelar cotización -->
        <v-tooltip bottom v-if="quote.state === 'sended_quote'">
          <template v-slot:activator="{ on }">
            <i-btn
              v-on="on"
              :title="$t('cancel_specific', { value: $t('quote') })"
              outlined
              classes="mx-1"
              icon="fa-times"
              color="error"
              @click="update('canceled')"
            />
          </template>
        </v-tooltip>

        <!-- Botones para descargar o imprimir cotización -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <i-btn
              v-on="on"
              :title="$t('download', { value: $t('quote') })"
              outlined
              classes="mx-1"
              icon="fa-file-download"
              color="error"
              @click="getPdf(true)"
            />
            <i-btn
              v-on="on"
              :title="$t('print', { value: $t('quote') })"
              outlined
              classes="mx-1"
              icon="fa-file-pdf"
              color="error"
              @click="getPdf()"
            />
          </template>
        </v-tooltip>

        <!-- Botón para agregar un producto -->
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <i-btn
              v-if="quote.state === 'quote' && orderProductBtn.add"
              v-on="on"
              :title="`${$t('add', { value: $tc('product', 2) })}`"
              outlined
              classes="mx-1"
              icon="fa-cart-plus"
              color="info"
              @click="$emit('addProd')"
            />
          </template>
        </v-tooltip>

        <!-- Título de la tarjeta -->
        <v-icon v-text="'icon-request'" class="primary--text mr-3" />
        <span> {{ $t('quote') }} # {{ quote.code }} </span>
      </v-card-title>

      <v-card-text>
        <v-row no-gutters>
          <!-- Nombre de la empresa del cliente -->
          <v-col
            cols="12"
            :class="{
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              name="customer"
              disabled
              :value="customer.company_name"
              readonly
              :label="$tc('customerCompany', 1)"
            />
          </v-col>

          <!-- Contacto del cliente -->
          <v-col
            cols="12"
            :class="{
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              name="contact"
              disabled
              :value="`${customer.first_name} ${customer.last_name}`"
              readonly
              :label="$tc('contact', 1)"
            />
          </v-col>

          <!-- Teléfono del cliente -->
          <v-col
            cols="12"
            :class="{
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              name="phone"
              disabled
              :value="`${customer.phone}`"
              readonly
              :label="$tc('phone', 1)"
            />
          </v-col>

          <!-- Email del cliente -->
          <v-col
            cols="12"
            :class="{
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              name="email"
              disabled
              :value="`${customer.email}`"
              readonly
              :label="$tc('email', 1)"
            />
          </v-col>

          <!-- Fecha del pedido -->
          <v-col
            cols="12"
            :class="{
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              name="date"
              disabled
              :value="dateFormat(order.date)"
              prepend-icon="fa-calendar"
              readonly
              :label="$tc('date', 1)"
            />
          </v-col>

          <!-- Fecha compromiso -->
          <v-col
            cols="12"
            :class="{
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              name="promise_date"
              disabled
              :value="dateFormat(order.promise_date)"
              prepend-icon="fa-calendar"
              readonly
              :label="$tc('promise_date', 1)"
            />
          </v-col>

          <!-- Campos para las direcciones del cliente -->
          <v-col
            cols="12"
            :class="{
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
            sm="6"
            md="4"
            lg="3"
            v-for="(address, inx) in customer.address_info"
            :key="`address-1-${inx}`"
          >
            <v-text-field
              :name="
                address.address_type !== 'SHIP'
                  ? 'customerAddress'
                  : 'shippingAddress'
              "
              disabled
              :value="getAddressName(address)"
              readonly
              :label="
                $tc(
                  address.address_type !== 'SHIP'
                    ? 'customerAddress'
                    : 'shippingAddress',
                  1
                )
              "
            />
          </v-col>

          <!-- Vendedor asignado -->
          <v-col
            cols="12"
            :class="{
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              name="salesman"
              disabled
              :value="`${order.name_salesman}`"
              readonly
              :label="$tc('salesman', 1)"
            />
          </v-col>

          <!-- Nombre del pedido -->
          <v-col
            cols="12"
            :class="{
              'pr-3': $vuetify.breakpoint.smAndUp
            }"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              name="order_name"
              disabled
              :value="`${order.nameOrder}`"
              readonly
              :label="$tc('order_name', 1)"
            />
          </v-col>
        </v-row>
        <!-- Mensaje si no hay productos en el carrito -->
        <v-col
          align="center"
          justify="center"
          class="display-1 font-weight-black grey--text"
          cols="12"
        >
          {{
            cartlist.length > 0 ? '' : $vuetify.lang.t('$vuetify.noDataText')
          }}
        </v-col>

        <!-- Lista de productos -->
        <v-list dense>
          <v-list-group
            v-for="(product, key) in cartlist"
            :key="`prouct-${key}`"
            :style="{
              'border-top':
                key !== 0
                  ? ''
                  : `2px solid ${$vuetify.theme.themes.light.primary}`,
              'border-bottom': `2px solid ${$vuetify.theme.themes.light.primary}`,
              'border-left': `2px solid ${$vuetify.theme.themes.light.primary}`,
              'border-right': `2px solid ${$vuetify.theme.themes.light.primary}`
            }"
            @click="searchProductInfo(product.pk)"
          >
            <v-icon slot="appendIcon" class="white--text" size="12"
              >fas fa-arrow-down</v-icon
            >
            <template v-slot:activator>
              <v-list-item-content>
                <v-row>
                  <!-- Código del producto -->
                  <v-col cols="6" sm="4" lg="3" v-if="product.code !== ''">
                    <span class="primary--text body-2 font-weight-black">
                      {{ $tc('code', 2) }} : <br />
                    </span>
                    <span class="primary--text body-2 front-weight-black">
                      {{ product.code }}
                    </span>
                  </v-col>

                  <!-- Nombre del producto -->
                  <v-col cols="6" sm="4" lg="3">
                    <span class="primary--text body-2 font-weight-black">
                      {{ $tc('subname', 2) }} : <br />
                    </span>
                    <span class="primary--text body-2 front-weight-black">
                      {{ product.name }}
                    </span>
                  </v-col>

                  <!-- Tipo de producto -->
                  <v-col cols="6" sm="4" lg="3">
                    <span class="primary--text body-2 font-weight-black">
                      {{ $tc('product', 1) }} : <br />
                    </span>
                    <span class="primary--text body-2 front-weight-black">
                      {{ product.own_prod }}
                    </span>
                  </v-col>

                  <!-- Cantidad del producto -->
                  <v-col cols="6" sm="4" lg="2">
                    <span class="primary--text body-2 font-weight-black">
                      {{ $tc('amount', 2) }} : <br />
                    </span>
                    <span class="primary--text body-2 front-weight-black">
                      {{ product.quantity }}
                    </span>
                  </v-col>

                  <!-- Precio del producto -->
                  <v-col cols="6" sm="4" lg="3">
                    <span class="primary--text body-2 font-weight-black">
                      {{ $tc('price', 1) }} : <br />
                    </span>
                    <span class="primary--text body-2 front-weight-black">
                      <i-money v-model="product.price" mode="text" />
                    </span>
                  </v-col>

                  <!-- Subtotal del producto -->
                  <v-col cols="6" sm="4" lg="3">
                    <span class="primary--text body-2 font-weight-black">
                      {{ $tc('subtotal', 1) }} : <br />
                    </span>
                    <span class="primary--text body-2 front-weight-black">
                      <i-money
                        :value="
                          Number(product.subtotal) + Number(product.discount)
                        "
                        mode="text"
                      />
                    </span>
                  </v-col>

                  <!-- Descuento del producto -->
                  <v-col
                    cols="6"
                    sm="4"
                    lg="3"
                    class="red--text"
                    v-if="product.discount > 0"
                  >
                    <span class="primary--text body-2 font-weight-black">
                      {{ $tc('discount', 1) }} : <br />
                    </span>
                    <span class="primary--text body-2 front-weight-black">
                      <i-money v-model="product.discount" mode="text" />
                    </span>
                  </v-col>

                  <!-- Impuesto del producto -->
                  <v-col cols="6" sm="4" lg="3">
                    <span class="primary--text body-2 font-weight-black">
                      {{ $tc('tax', 1) }} : <br />
                    </span>
                    <span class="primary--text body-2 front-weight-black">
                      <i-money v-model="product.tax" mode="text" />
                    </span>
                  </v-col>

                  <!-- Total del producto -->
                  <v-col cols="6" sm="4" lg="2">
                    <span class="primary--text body-2 font-weight-black">
                      {{ $tc('total', 1) }} : <br />
                    </span>
                    <span class="primary--text body-2 front-weight-black">
                      <i-money v-model="product.total" mode="text" />
                    </span>
                  </v-col>

                  <!-- Botones para editar y eliminar productos -->
                  <v-col
                    cols="6"
                    sm="4"
                    lg="2"
                    v-if="
                      status === 'quote' &&
                      quote.state !== 'approved' &&
                      quote.state !== 'correction'
                    "
                  >
                    <i-btn
                      v-if="orderProductBtn.edit"
                      :title="$tc('edit', 1)"
                      outlined
                      classes="mx-1"
                      icon="mdi-pencil"
                      color="info"
                      @click.stop="$refs.dialprod.open(product.pk)"
                    />
                    <i-btn
                      v-if="orderProductBtn.delete"
                      :title="$tc('delete', 1)"
                      outlined
                      classes="mx-1"
                      icon="fa-trash"
                      color="error"
                      @click.stop="remove(product.pk)"
                    />
                  </v-col>

                  <!-- Switch para la entrega del producto -->
                  <v-col cols="2" sm="2" lg="2">
                    <v-switch
                      v-model="product.delivery"
                      :label="$tc('delivery', true)"
                      name="email"
                      color="primary"
                      key="active-input"
                      @click.stop="editDelivery(product.pk, product.delivery)"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </template>

            <!-- Información adicional del producto -->
            <v-row class="white mx-0 px-1">
              <v-col
                cols="12"
                sm="6"
                md="4"
                v-show="product.description !== ''"
              >
                <v-subheader class="pl-0 primary--text font-weight-bold">
                  {{ $t('description') }}
                </v-subheader>
                <div class="infocard pa-1" v-html="product.description"></div>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                v-show="product.additional_side !== ''"
              >
                <v-subheader class="pl-0 primary--text font-weight-bold">
                  {{ $t('additional_side') }}
                </v-subheader>
                <div
                  class="infocard pa-1"
                  v-html="product.additional_side"
                ></div>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                v-if="
                  informationExtra[product.pk] === undefined ||
                  informationExtra[product.pk]['dates'] === undefined ||
                  (informationExtra[product.pk] !== undefined &&
                    informationExtra[product.pk]['dates'] !== undefined &&
                    informationExtra[product.pk]['dates'].length > 0)
                "
              >
                <v-subheader class="pl-0 primary--text font-weight-bold">
                  {{ $t('dates') }}
                </v-subheader>
                <div
                  v-if="
                    informationExtra[product.pk] !== undefined &&
                    informationExtra[product.pk]['dates'] !== undefined
                  "
                >
                  <div
                    class="infocard px-1 my-1"
                    v-for="(date, keydate) in informationExtra[product.pk][
                      'dates'
                    ].filter((m) => !m.changeDate)"
                    :key="'dates-' + keydate"
                    style="display: flex; justify-content: space-between"
                  >
                    <span class="font-weight-black">{{ date.typeName }}</span>
                    <span>
                      {{ dateFormat(date.date) }}
                    </span>
                  </div>
                </div>
                <v-skeleton-loader v-else type="text" tile> </v-skeleton-loader>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                v-if="chekdimensions(product.dimensions)"
              >
                <v-subheader class="pl-0 primary--text font-weight-bold">
                  {{ $t('dimension') }}
                </v-subheader>
                <i-card-dimensions
                  readonly
                  :value="product.dimensions"
                ></i-card-dimensions>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                v-if="
                  informationExtra[product.pk] === undefined ||
                  informationExtra[product.pk]['details'] === undefined ||
                  (informationExtra[product.pk] !== undefined &&
                    informationExtra[product.pk]['details'] !== undefined &&
                    informationExtra[product.pk]['details'].filter(
                      (m) => !m.hidden
                    ).length > 0)
                "
              >
                <v-subheader class="pl-0 primary--text font-weight-bold">
                  {{ $t('details_production') }}
                </v-subheader>
                <div
                  v-if="
                    informationExtra[product.pk] !== undefined &&
                    informationExtra[product.pk]['details'] !== undefined
                  "
                >
                  <div
                    v-for="(pos, keyattr) in informationExtra[product.pk][
                      'details'
                    ].filter((m) => !m.hidden)"
                    :key="`attri-${keyattr}`"
                  >
                    <i-card-dimensions
                      readonly
                      :value="pos['dimensions']"
                      :name="pos['name']"
                      :price="pos['price']"
                      :detail="pos"
                      :qty="product.quantity"
                    ></i-card-dimensions>
                  </div>
                </div>
              </v-col>
            </v-row>
            <!-- end extra information -->
          </v-list-group>
        </v-list>
        <v-row
          no-gutters
          class="text-right body-2 font-weight-black primary--text pr-4"
        >
          <v-col cols="12">
            <i-money
              mode="text"
              :value="Number(order.subtotal) + Number(discount)"
              :label="`${$t('subtotal')}:`"
            />
          </v-col>
          <v-col cols="12">
            <i-money
              mode="text"
              :label="$t('tax')"
              :value="order.tax"
              class="font-weight-black"
            />
          </v-col>
          <v-col cols="12">
            <i-money
              mode="text"
              :label="$t('discount')"
              v-if="discount > 0"
              :value="discount"
              class="font-weight-black red--text"
            />
          </v-col>
          <v-col cols="12">
            <i-money
              class="headline font-weight-black"
              mode="text"
              :value="order.total"
              :label="`${$t('total')}:`"
            />
          </v-col>
        </v-row>
        <!-- end products list -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import dialprod from './DialogProduct.vue'
import getBodyEmail from '../email/getBodyEmail.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    dialprod,
    getBodyEmail
  },
  props: {
    order: {
      required: true,
      type: Object
    },
    customer: {
      required: false,
      type: Object
    },
    status: {
      type: String
    },
    cartlist: {
      type: Array,
      default: () => []
    },
    view: {
      type: Boolean,
      default: true
    },
    completedDelivery: {
      type: Boolean
    }
  },
  data() {
    return {
      selectedItem: null,
      informationExtra: {},
      load: false,
      mode: null,
      pkRemove: null,
      quote: {
        pk: '',
        code: '',
        state: '',
        date: '',
        mail_date: null,
        customer: null,
        salesman: null,
        order: null
      }
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin',
      me: 'session/me'
    }),
    /**
     * orderProductBtn
     * Calcula los permisos para agregar, editar y eliminar productos del pedido.
     * Devuelve un objeto con las propiedades add, edit y delete.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    orderProductBtn() {
      return {
        add:
          (this.getPermissions([`sales.add_orderproduct`]) &&
            this.getPermissions([`sales.change_quotation`])) ||
          this.isAdmin,
        edit:
          (this.getPermissions([`sales.change_orderproduct`]) &&
            this.getPermissions([`sales.change_quotation`])) ||
          this.isAdmin,
        delete:
          (this.getPermissions([`sales.delete_orderproduct`]) &&
            this.getPermissions([`sales.change_quotation`])) ||
          this.isAdmin
      }
    },
    /**
     * changeStatus
     * Calcula los permisos para cambiar el estado del pedido.
     * Devuelve un objeto con la propiedad edit.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    changeStatus() {
      return {
        edit:
          (this.getPermissions([`sales.change_orderstatus`]) &&
            this.getPermissions([`sales.change_quotation`])) ||
          this.isAdmin
      }
    },
    /**
     * discount
     * Calcula el total de descuentos aplicados en los productos del carrito.
     * Devuelve el total de descuentos formateado a dos decimales.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    discount() {
      if (
        this.cartlist !== undefined &&
        this.cartlist !== null &&
        this.cartlist.length > 0
      )
        return this.cartlist
          .reduce(
            (accumulator, currentValue) =>
              accumulator + Number(currentValue.discount),
            0
          )
          .toFixed(2)
      return 0
    },
    /**
     * cardTitleClasses
     * Devuelve un objeto con las clases CSS para el título de la tarjeta.
     * Se ajusta según el tamaño de la pantalla.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    cardTitleClasses() {
      return {
        'primary--text': true,
        'font-weight-bold': true,
        headline: !this.$vuetify.breakpoint.xsOnly,
        title: this.$vuetify.breakpoint.xsOnly,
        'pb-1': true
      }
    }
  },
  methods: {
    ...mapActions({
      showConfirmation: 'confirmation/confirmationValue'
    }),
    /**
     * remove
     * Maneja la eliminación de un producto del carrito.
     * Muestra una confirmación antes de proceder con la eliminación.
     *
     * @param {Number} pk - Identificador del producto a eliminar.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    remove(pk) {
      this.pkRemove = pk
      this.showConfirmation({
        show: true,
        model: this.$tc('product', 1),
        title: this.$tc('delete', 1),
        type: 'error white--text',
        content: this.$t('confirm_delete'),
        alert: `${this.$t('success', {
          model: this.$tc('product', 1),
          action: this.$tc('delete', 2)
        })}`,
        confirm: () => this.$api.sale.product.remove({ pk })
      })
    },
    /**
     * getPdf
     * Descarga un archivo PDF de la cotización.
     *
     * @param {Boolean} down - Indica si se debe descargar o abrir en una nueva
     *                         ventana.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    getPdf(down) {
      this.downloadFile({
        api: 'quotation',
        method: 'pdf',
        filename: 'Quotation',
        action: down ? 'download' : 'window',
        ext: 'pdf',
        opt: { pk: this.quote.order }
      })
    },
    /**
     * getAddressName
     * Genera una cadena con la dirección formateada.
     *
     * @param {Object} address - Objeto que contiene la dirección.
     * @returns {String} - Dirección formateada.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    getAddressName(address) {
      let name = `${address.address1}, `
      name += address.address2 != '' ? `${address.address2}, ` : ''
      name += `${address.city}, ${address.state}`
      return name
    },
    /**
     * chekdimensions
     * Verifica si un objeto tiene propiedades.
     *
     * @param {Object} item - Objeto a verificar.
     * @returns {Boolean} - True si tiene propiedades, false en caso contrario.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    chekdimensions(item) {
      return Object.keys(item).length > 0
    },
    /**
     * searchProductInfo
     * Busca información adicional sobre un producto dado su ID.
     * Realiza solicitudes a la API para obtener detalles y fechas.
     *
     * @param {Number} id - Identificador del producto.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    searchProductInfo(id) {
      if (this.informationExtra[id] === undefined) {
        try {
          this.load = true
          this.$api.suborder.date
            .show({
              pk: id,
              opt: {
                params: {
                  order: true
                }
              }
            })
            .then((response) => {
              this.$set(this.informationExtra, id, {
                ...this.informationExtra[id],
                dates: response.data
              })
            })
          this.$api.suborder.production
            .show({
              pk: id,
              opt: {
                params: {
                  subOrder: true
                }
              }
            })
            .then((response) => {
              this.$set(this.informationExtra, id, {
                ...this.informationExtra[id],
                details: response.data
              })
            })
        } finally {
          this.load = false
        }
      }
    },
    /**
     * update
     * Actualiza el estado del pedido según el modo especificado.
     * Muestra una confirmación antes de proceder con la actualización.
     *
     * @param {String} mode - Modo de actualización
     *                        (send, canceled, approved, correction, finished,
     *                        production_ready).
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    update(mode) {
      this.mode = mode
      let state = ''
      let title = ''
      switch (mode) {
        case 'send':
          state = 'sended_quote'
          title = this.$tc('send', 1)
          break
        case 'canceled':
          state = 'canceled'
          title = this.$t('cancel')
          break
        case 'approved':
          state = 'approved'
          title = this.$t('approve')
          break
        case 'correction':
          state = 'correction'
          title = this.$t('correction')
          break
        case 'finished':
          state = 'finished'
          title = ''
          break
        case 'production_ready':
          state = 'production_ready'
          title = ''
          break
      }
      this.showConfirmation({
        show: true,
        model:
          state === 'finished'
            ? this.$t('finishedAndRemove')
            : this.$tc('quote', 1),
        title: title,
        type: 'warning white--text',
        content:
          state === 'finished'
            ? this.$t('are_sure')
            : this.$t('areSure', {
                action: title,
                model: state === 'finished' ? '' : this.$tc('quote', 1)
              }),
        alert: `${this.$t('success', {
          model: state === 'finished' ? '' : this.$tc('quote', 1),
          action: this.$tc('update', 2)
        })}`,
        confirm: () => this.edit(state)
      })
    },
    /**
     * edit
     * Maneja la edición del estado de la cotización.
     * Emite un evento al finalizar la operación.
     *
     * @param {String} state - Nuevo estado de la cotización.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async edit(state) {
      this.quote.state = state
      this.getOrderStatus(state)
      if (this.quote.state === 'finished') {
        this.removeRepository()
      }
      if (state === 'sended_quote') {
        this.$refs['getbodyemail'].getBodyEmail()
      }
      this.$emit('done')
    },
    /**
     * removeRepository
     * Elimina el repositorio de imágenes asociado al pedido.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async removeRepository() {
      try {
        this.load = true
        let response = await this.$api.sale.removerepository({
          pk: this.order.pk
        })
      } finally {
        this.load = false
      }
    },
    /**
     * getQuote
     * Crea una nueva cotización o actualiza una existente.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getQuote() {
      if (this.quote.order && this.quote.customer) {
        const haveQuote = this.quote.pk > 0
        let d = new Date()
        this.quote.date = `${d.getFullYear()}-${(
          '0' +
          (d.getMonth() + 1)
        ).slice(-2)}-${('0' + d.getDate()).slice(-2)}`
        try {
          const { data: quotation } = await this.$api.quotation.create({
            form: this.quote
          })
          this.quote = quotation
          this.quote.state = 'quote'
          this.getOrderStatus('quote')
        } catch (error) {
          console.error('Error al crear la cotización:', error)
        }
      }
    },
    /**
     * updateValue
     * Actualiza el valor de un producto en el carrito.
     * Emite un evento con la lista actualizada.
     *
     * @param {Object} evt - Evento que contiene la información del producto.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    updateValue(evt) {
      this.$emit(
        'update:cartlist',
        this.cartlist.map((m) => {
          if (m.pk === evt.pk) {
            return { ...evt, updateProduct: true }
          }
          return m
        })
      )
    },
    /**
     * editDelivery
     * Edita la información de entrega de un producto en el carrito.
     *
     * @param {Number} pk - Identificador del producto.
     * @param {Object} delivery - Información de entrega a actualizar.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async editDelivery(pk, delivery) {
      let orderproduct = this.cartlist.filter((m) => m.pk == pk)[0]
      orderproduct.delivery = delivery
      let form_data = new FormData()
      for (var key in orderproduct) {
        if (orderproduct[key] == null) {
          continue
        }
        form_data.append(
          key,
          key !== 'dimensions'
            ? orderproduct[key]
            : JSON.stringify(orderproduct[key])
        )
        if (
          key == 'thumbnail' &&
          (orderproduct.thumbnail == null ||
            String(orderproduct.thumbnail).indexOf(';base64') != -1 ||
            String(orderproduct.thumbnail).indexOf('http') != -1)
        ) {
          form_data.delete('thumbnail')
        }
      }
      await this.$api.sale.product.edit({
        pk: pk,
        form: form_data,
        opt: {
          params: {
            delivery: true
          }
        }
      })
    },
    /**
     * getOrderStatus
     * Obtiene el estado del pedido para cada producto en el carrito.
     * Actualiza el estado en el dashboard.
     *
     * @param {String} state - Estado a verificar.
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getOrderStatus(state) {
      if (!state) {
        console.log('El estado no puede estar vacío')
        return
      }

      // Iteramos sobre la lista de productos en el carrito
      for (const product of this.cartlist) {
        if (!product.template) {
          console.log('Este producto no tiene plantilla')
          continue
        }

        try {
          const response = await this.$api.sale.status.list({
            opt: {
              params: {
                template: product.template,
                state_main: state
              }
            }
          })
          if (response.data.length > 0) {
            const newStatus = response.data[0]
            this.form = {
              product: product.pk,
              preprocess: state === 'approved' ? 'in_process' : state
            }

            // Editamos el estado en el dashboard
            await this.$api.dashboard.edit({
              pk: newStatus.pk,
              form: this.form
            })
          } else {
            console.log(
              'No existe el estado para la plantilla de este producto'
            )
          }
        } catch (error) {
          console.error('Error al obtener el estado de la orden:', error)
        }
      }
    }
  },
  watch: {
    status: {
      immediate: true,
      handler() {
        //
      }
    },
    order: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val !== undefined && val !== null) {
          if (this.quote.order !== val.pk) {
            this.quote.order = val.pk
          }
          if (this.quote.pk === '' || this.quote.pk === null) {
            this.getQuote()
          }
        }
      }
    },
    customer: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val !== undefined && val !== null) {
          if (this.quote.customer !== val.pk) {
            this.quote.customer = val.pk
          }
          if (this.quote.pk === '' || this.quote.pk === null) {
            this.getQuote()
          }
        }
      }
    },
    cartlist: {
      immediate: true,
      deep: true,
      handler(val) {
        for (let x in val) {
          if (val[x]['updateProduct'] !== undefined) {
            delete this.informationExtra[val[x]['pk']]
            this.searchProductInfo(val[x]['pk'])
          }
        }
        let subtotal = this.cartlist
          .reduce(
            (accumulator, currentValue) =>
              accumulator + Number(currentValue.subtotal),
            0
          )
          .toFixed(2)
        let tax = this.cartlist
          .reduce(
            (accumulator, currentValue) =>
              accumulator + Number(currentValue.tax),
            0
          )
          .toFixed(2)
        let total = (Number(subtotal) + Number(tax)).toFixed(2)
        this.$emit('subtotalOrder', subtotal)
        this.$emit('taxOrder', tax)
        this.$emit('totalOrder', total)
      }
    }
  },
  created() {
    this.unwatch = this.$store.watch(
      () => this.$store.getters['confirmation/getConfirmationFinished'],
      async (val) => {
        if (val === this.$tc('product', 1) && this.pkRemove !== null) {
          this.$store.dispatch('confirmation/confirmationFinished', false)
          this.$emit(
            'update:cartlist',
            this.cartlist.filter((m) => m.pk !== this.pkRemove)
          )
          this.pkRemove = null
        }
        if (
          (val === this.$tc('quote', 1) ||
            val === this.$t('finishedAndRemove')) &&
          this.mode !== null
        ) {
          switch (this.mode) {
            case 'send':
              this.quote.state = 'sended_quote'
              this.$emit('update:status', 'sended_quote')
              break
            case 'canceled':
              this.quote.state = 'canceled'
              this.$emit('update:status', 'canceled')
              break
            case 'approved':
              this.quote.state = 'approved'
              this.$emit('update:status', 'in_process')
              break
            case 'correction':
              this.quote.state = 'correction'
              this.$emit('update:status', 'quote')
              break
            case 'finished':
              this.quote.state = 'finished'
              this.$emit('update:status', 'finished')
              break
          }
          this.mode = null
        }
      }
    )
  },
  beforeDestroy() {
    this.unwatch()
  }
}
</script>
